import { render, staticRenderFns } from "./saleOrOpening.vue?vue&type=template&id=18f8b7bd&scoped=true&"
import script from "./saleOrOpening.vue?vue&type=script&lang=js&"
export * from "./saleOrOpening.vue?vue&type=script&lang=js&"
import style0 from "./saleOrOpening.vue?vue&type=style&index=0&id=18f8b7bd&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18f8b7bd",
  null
  
)

export default component.exports