import request from '@/utils/request'

/**
 *
 * get-params
 * post-data
 */

//获取首页banner图
export function getBanner(params) {
  return request({
    url: '/newhome/guide',
    method: 'get',
    params,
  })
}

//获取深圳地区
export function getArea(params) {
  return request({
    url: '/city/cql',
    method: 'get',
    params,
  })
}

//获取价格区间
export function getPrice(params) {
  return request({
    url: '/unit/list',
    method: 'get',
    params,
  })
}

//获取新房列表
export function getHouse(params) {
  return request({
    url: '/newhome/cxfmtpurl',
    method: 'get',
    params,
  })
}

//获取临深楼盘列表
export function getSzHouse(params) {
  return request({
    url: '/newhome/cxfmtpurl',
    method: 'get',
    params,
  })
}

//获取媒体软文
export function getMedium(params) {
  return request({
    url: '/journ/ams',
    method: 'get',
    params,
  })
}

//获取新房详情
export function getHouseInfo(params) {
  return request({
    url: '/newhome/list',
    method: 'get',
    params,
  })
}

//获取新房详情-顶部轮播图
export function getHouseInfoImg(params) {
  return request({
    url: '/photo/htlist',
    method: 'get',
    params,
  })
}

//获取户型数据
export function getHouseTypeInfo(params) {
  return request({
    url: '/family/list',
    method: 'get',
    params,
  })
}
//报名列表
export function getOrder(params) {
  return request({
    url: '/apply/list',
    method: 'get',
    params,
  })
}


//报名预约
export function goOrder(data) {
  return request({
    url: '/apply/ins',
    method: 'post',
    data,
  })
}

//楼盘详情视频
export function getVideo(params) {
  return request({
    url: '/photo/video',
    method: 'get',
    params,
  })
}

//销售团队列表
export function getSale(params) {
    return request({
      url: '/lyfigure/lyfigurelist',
      method: 'get',
      params,
    })
  }

  //楼盘百科
export function getWiki(params) {
  return request({
    url: '/lpcyclopedia/lpcyclopedialist',
    method: 'get',
    params,
  })
}


// 获取楼盘point经纬度
export function getPoint(params) {
  return request({
    url: '/point/list/lpid',
    method: 'get',
    params,
  })
}

// 获取別墅列表
export function getVilla(params) {
  return request({
    url: '/villa/villaList',
    method: 'get',
    params,
  })
}

// 获取別墅詳情
export function getVillaDetail(params) {
  return request({
    url: '/villa/villainfoList',
    method: 'get',
    params,
  })
}

// 获取別墅详情轮播图
export function getVillaImg(params) {
  return request({
    url: '/photo/villalist',
    method: 'get',
    params,
  })
}

// 获取別墅软文
export function getVillaArticle(params) {
  return request({
    url: '/villaJournalism/villaJournalismList',
    method: 'get',
    params,
  })
}

// 获取拉页详情
export function getlyInfo(params) {
  return request({
    url: '/pull/list',
    method: 'get',
    params,
  })
}

// 获取拉页图片
export function getlyImg(params) {
  return request({
    url: '/pull/lyimage/list',
    method: 'get',
    params,
  })
}

//拉页报名
export function lyCommit(data) {
  return request({
    url: '/pull/lyuser/ins',
    method: 'post',
    data,
  })
}

//拉页电话点击数
export function lyCounter(data) {
  return request({
    url: '/pull/lydjinfo/ins',
    method: 'post',
    data,
  })
}

//附近在售列表
export function getNear(params) {
  return request({
    url: '/newhome/nearlpinfo',
    method: 'get',
    params,
  })
}

// 写字楼列表
export function getOffice(params) {
  return request({
    url: '/ofs/list',
    method: 'get',
    params,
  })
}

// 写字楼图片列表
export function getOfficeImg(params) {
  return request({
    url: '/op/list',
    method: 'get',
    params,
  })
}

// 获取所有地区
export function getAllArea(params) {
  return request({
    url: '/area/qycode',
    method: 'get',
    params,
  })
}

// 获取所有城市
export function getAllCity(params) {
  return request({
    url: 'area/urban',
    method: 'get',
    params,
  })
}

// 获取房产视频
export function getAllVideo(params) {
  return request({
    url: 'lpvideo/lpvideolist',
    method: 'get',
    params,
  })
}
