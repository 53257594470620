<template>
  <div class="footer">
    <div class="footer-1">
      <el-col :span="12">
        <h1 style="text-align: left">
          <router-link to="/">
            <img
              src="https://lyimgs.oss-cn-shenzhen.aliyuncs.com/websiteImg/logofoot.png"
              alt="多点房首页"
            />
            <!-- 安全证书 -->
            <a
              href="https://xyt.xcc.cn/getpcInfo?sn=1740567120506208256&language=CN&certType=8&url=*.duodianfang.com"
              target="_blank"
              style="display: inline-block; height: 38px; margin-left: 30px"
              @click="goPages()"
            >
              <div
                style="
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                "
              ></div>
              <embed
                src="https://program.xinchacha.com/web/1740567120506208256=*.duodianfang.com.svg"
                width="103"
                height="38"
                type="image/svg+xml"
                pluginspage="//www.adobe.com/svg/viewer/install/"
            /></a>
          </router-link>
        </h1>
      </el-col>
      <!-- <el-col :span="12">
        <span class="label">
          <router-link to="/aboutus">
            <el-link :underline="false"> 关于我们 </el-link>
          </router-link>
        </span>
        <span class="label">
          <router-link to="/contactus">
            <el-link :underline="false"> 联系我们 </el-link>
          </router-link>
        </span>
        <span class="label">
          <router-link to="/ddfzgz">
            <el-link :underline="false"> 多点房百度代理资质 </el-link>
          </router-link>
        </span>
      </el-col> -->
    </div>
    <div class="footer-2">
      <el-row>
        <el-col :span="6" class="ft-zx">
          <el-row class="ft-title">新闻资讯</el-row>
          <el-row class="ft-item">
            <a href="/pages/news/index">资讯</a>
            <a href="/">新房动态</a>
          </el-row>
        </el-col>
        <el-col :span="6" class="ft-zx">
          <el-row class="ft-title">联系我们</el-row>
          <el-row class="ft-item">
            <router-link to="/aboutUs"> 公司简介 </router-link>
            <router-link to="/contactUs"> 联系我们 </router-link>
          </el-row>
        </el-col>
        <el-col :span="6" class="ft-zx">
          <el-row class="ft-title">服务支持</el-row>
          <el-row class="ft-item">
            <router-link to="/pages/index/relief"> 免责声明 </router-link>
            <!-- <router-link to="/guide"> 购房指南 </router-link> -->
          </el-row>
        </el-col>
        <el-col :span="6" class="ft-zx">
          <el-row class="ft-title">友情链接</el-row>
          <el-row class="ft-item">
            <a href="http://www.baifun.cn/" target="_blank">
              百房网络科技有限公司
            </a>
            <a
              href="https://author.baidu.com/home?from=bjh_article&app_id=1696532011522340"
              target="_blank"
            >
              百度房产深圳站
            </a>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div class="footer-2 footer-3">
      <el-row>
        <el-col :span="6">© CopyRight 2018-2021</el-col>
        <el-col :span="6">
          <a href="https://beian.miit.gov.cn">备案号: 粤ICP备18083769号-1</a>
        </el-col>
        <el-col :span="6" class="ft-dh">
          <img
            src="https://lyimgs.oss-cn-shenzhen.aliyuncs.com/websiteImg/phone.gif"
            alt="多点房联系电话0755-27809950"
          />
          咨询热线：<a href="tel:0755-27809950">0755-27809950</a>
        </el-col>
        <el-col :span="6" class="ft-dh">
          <i class="el-icon-message"></i> 邮箱：<a href="mailto:david@baifun.cn"
            >david@baifun.cn</a
          >
        </el-col>
      </el-row>
    </div>
    <div class="a-hide">
      <a href="http://mobile.duodianfang.com">移动端多点房</a>
      <a href="http://mobile.baifun.cn">移动端百房</a>
      <a href="http://www.duodianfang.com">多点房</a>
      <a href="http://www.baifun.cn">百房</a>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    goPages() {
      window.location.href = 'https://xyt.xcc.cn/getpcInfo?sn=1740567120506208256&language=CN&certType=8&url=*.duodianfang.com'
    }
  }
}
</script>

<style lang="scss">
.footer {
  position: relative;
  bottom: 0;
  background-color: #363a3d;
  width: 100%;
  height: auto;
  z-index: 100000;
}
.footer .footer-1 {
  border-bottom: 0.1px solid #636363;
  height: 48px;
  width: 1250px;
  margin: 0 auto;
  padding: 15px 0;
  img {
    padding-top: -1%;
    height: 46px;
    float: left;
  }
  .label {
    height: 36px;
    line-height: 36px;
    float: right;
    padding: 0 10px;
    border: 1px solid #636363;
    border-radius: 18px;
    margin-top: 10px;
    margin-left: 5px;
  }
  .el-link.el-link--default {
    color: #fff;
    margin-top: 0;
  }
}
.footer-2 {
  height: auto;
  width: 1250px;
  margin: 0 auto;
  padding: 15px 0;
  border-bottom: 0.1px solid #636363;
  .ft-zx {
    color: #fff;
    .ft-title {
      font-size: 18px;
      margin-bottom: 20px;
    }
    .ft-item {
      font-size: 16px;
      a {
        color: #adaeaf;
        display: block;
        padding: 5px 0;
      }
    }
  }
}
.footer-3 {
  border: none;
  color: #adaeaf;
  .ft-dh {
    color: #fff;
    img {
      width: 20px;
      height: 20px;
      vertical-align: bottom;
    }
    a {
      color: #fff;
    }
  }
}
.footer a {
  color: #adaeaf;
  text-decoration: none;
}

.a-hide {
  width: 1px;
  display: none;
  font-size: 12px;
}
</style>
