<template>
  <div id="app">
    <!-- 页面顶部 -->
    <nav :class="isInfomation ? 'navConTwo' : 'navCon'" v-if="isSow">
      <div class="cityCon">
        <img
          src="https://lyimgs.oss-cn-shenzhen.aliyuncs.com/websiteImg/logo.png"
          @click="goHome()"
          style="cursor: pointer"
        />
        <el-popover placement="bottom" width="400" trigger="hover">
          <div class="popover">
            <p
              v-for="item in areaList"
              :key="item.qybm"
              style="
                line-height: 3;
                display: flex;
                border-bottom: 1px solid #f2f2f2;
              "
            >
              <span style="display: block; width: 100px">{{
                item.qyname
              }}</span>
              <template
                v-for="city in cityList"
              >
                  <span :key="city.ctid" v-if="city.qybm == item.qybm" style="cursor: pointer;margin-right:20px" @click="selectCity(city)">{{
                    city.ctname
                  }}</span>
              </template>
            </p>
          </div>
          <el-button slot="reference" icon="el-icon-location-outline">{{
            cityName
          }}</el-button>
        </el-popover>
      </div>
      <div class="conCenter">
        <router-link to="/pages/index/index">首页</router-link>
        <router-link to="/newHouse">新房</router-link>
        <router-link to="/office">商铺写字楼</router-link>
        <router-link to="/pages/news/index">资讯</router-link>
        <router-link to="/allVideo">房产视频</router-link>
        <router-link to="/soft">媒体软文</router-link>
      </div>
      <div class="conRight" v-if="!isInfomation">
        <el-input
          placeholder="请输入内容"
          prefix-icon="el-icon-search"
          @change="goSearch()"
          v-model="topKeyWord"
        >
        </el-input>
      </div>
    </nav>
    <!-- 路由页面 -->
    <router-view class="routerView" />
    <!-- 页面底部 -->
    <bottomFooter v-if="showFoot && isSow" />
  </div>
</template>
<script>
import { getAllArea, getAllCity } from "@/api/home.js";
import bottomFooter from "@/components/subFooter.vue";
export default {
  components: { bottomFooter },
  data() {
    return {
      isInfomation: false,
      showFoot: true,
      topKeyWord: "",
      isSow: true,
      areaList: [],
      cityList: [],
      cityName: "深圳",
    };
  },
  created() {
    this.getArea();
    this.getCity();
  },
  watch: {
    $route(to, from) {
      this.$store.state.routeToName = to.name;
      this.$store.state.routerFromName = from.name;
      if (
        // to.name == 'InforMationindex' ||
        // to.name == 'InfoDetail' ||
        to.name == "WikiDetail" ||
        to.name == "TypeDetail" ||
        to.name == "RentDetail" ||
        to.name == "RentList" ||
        to.name == "VillaIndex" ||
        to.name == "VillaDetail" ||
        to.name == "SaleIndex" ||
        to.name == "AboutUs" ||
        to.name == "ContactUs" ||
        to.name == "DisClaimer" ||
        to.name == "ProMotion"
      ) {
        this.isInfomation = true;
      } else {
        this.isInfomation = false;
      }
      if (to.name == "SoftIndex") {
        this.showFoot = false;
      } else {
        this.showFoot = true;
      }
      if (to.name == "PullPage") {
        this.isSow = false;
      } else {
        this.isSow = true;
      }
    },
  },
  methods: {
    selectCity(info) {
      this.cityName = info.ctname;
      this.$store.state.ctid = info.ctid
      this.goHome()
    },
    goHome() {
      if (this.$route.name !== 'HomeIndex') {
        this.$router.push("/pages/index/index");
      }
    },
    goSearch() {
      if (this.topKeyWord) {
        localStorage.setItem("topKeyWord", this.topKeyWord);
        if (this.$store.state.routeToName != "NewHouse") {
          this.$router.push({
            path: "/newHouse",
          });
          setTimeout((this.topKeyWord = ""), 4000);
        } else {
          this.$router.go(0);
          return;
        }
      }
    },
    // 获取所有地区
    async getArea() {
      await getAllArea()
        .then((res) => {
          this.areaList = res.data;
        })
        .catch((err) => console.log(err))
        .finally(() => {});
    },

    // 获取所有城市
    async getCity() {
      await getAllCity({
        pageNum: 1,
        pageSize: 20,
      })
        .then((res) => {
          this.cityList = res.data.records;
        })
        .catch((err) => console.log(err))
        .finally(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  .navCon {
    box-shadow: 0px 0px 10px #e0dede;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #fff;
    margin: 0 auto;
    position: fixed;
    top: 0;
    z-index: 99;
    left: 0;
    right: 0;
    padding: 5px 0;

    .cityCon {
      display: flex;
      align-items: center;
      button {
        padding: 5px 10px;
      }
      img {
        width: 150px;
        height: 35px;
      }
    }

    .conCenter {
      min-width: 600px;
      a {
        color: #333;
        text-decoration-line: none;
        font-size: 16px;
        margin: 0 20px;

        &.router-link-exact-active {
          color: #000;
          font-weight: 600;
        }
      }
    }
    .conRight {
      min-width: 200px;
      :deep() {
        .el-input.is-active .el-input__inner,
        .el-input__inner:focus {
          border-color: #ccc !important;
        }
        .el-input__inner {
          height: 30px;
          line-height: 30px;
        }
        .el-input__icon {
          line-height: 30px;
        }
      }
    }
  }
  .navConTwo {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #363a3d;
    margin: 0 auto;
    position: fixed;
    top: 0;
    z-index: 99;
    left: 0;
    right: 0;
    padding: 5px 0;
    img {
      width: 150px;
      height: 35px;
    }
    .conCenter {
      min-width: 800px;
      margin-right: 50px;
      a {
        color: #fff;
        text-decoration-line: none;
        font-size: 16px;
        margin: 0 40px;

        &.router-link-exact-active {
          color: #fff;
          font-weight: 600;
        }
      }
    }
    .conRight {
      :deep() {
        .el-input.is-active .el-input__inner,
        .el-input__inner:focus {
          border-color: #ff1b01 !important;
        }
      }
    }
  }
}
</style>
