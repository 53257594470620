<template>
  <div class="Con">
    <!-- 媒体软文 -->
    <!-- <div class="softCon">
      <h1 class="softTitle">媒体软文</h1>
      <el-carousel height="150px">
        <el-carousel-item v-for="item in meaiumList" :key="item.nrid">
          <div>
            <h2 style="cursor: pointer" @click="goInfoDetail(item.nrid)">
              {{ item.title }}
            </h2>
            <div class="softMain">
              <span class="mainTop">
                <el-avatar :size="30" :src="item.issuephoto"></el-avatar>
                <span style="margin-left: 10px">{{ item.issuename }}</span>
              </span>
              <span class="mainFoot">发表日期：{{ item.issuedate }}</span>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div> -->
    <!-- 轮播图 -->
    <div class="conSwiper" style="margin-top: 0px">
      <img
        src="https://ddffile.oss-cn-shenzhen.aliyuncs.com/ddf/2023-08-31/1018.jpg"
        alt=""
        width="100%"
        height="500px"
        @click="toNewPage()"
      />
      <!-- <sub-top-swiper :type="bannerType" /> -->
    </div>
    <!-- 除轮播图以外的内容 -->
    <div style="width: 1250px; margin: 0 auto">
      <!-- 搜索 -->
      <div class="conSearch">
        <!-- 地域搜索 -->
        <div class="searchTop">
          <div class="region">
            <!-- 区域 -->
            <div class="area">
              <p class="areaTitle">区域</p>
              <div class="areaItem">
                <p
                  :class="item.isSelect ? 'isSelect' : 'unSelect'"
                  v-for="item in areaList"
                  :key="item.ctqybm"
                  @click="handelArea(item)"
                >
                  {{ item.ctqyname }}
                </p>
              </div>
            </div>
            <!-- 单价 -->
            <div class="price">
              <p class="priceTitle">单价</p>
              <div class="priceItem">
                <p
                  :class="item.isSelect ? 'isSelect' : 'unSelect'"
                  v-for="item in priceList"
                  :key="item.djid"
                  @click="handelPrice(item)"
                >
                  {{ item.dj }}
                </p>
              </div>
            </div>
          </div>
          <!-- 媒体软文 -->
          <div class="softCon">
            <h1 class="softTitle">媒体软文</h1>
            <el-carousel height="150px">
              <el-carousel-item v-for="item in meaiumList" :key="item.nrid">
                <div>
                  <h2
                    style="
                      cursor: pointer;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                    "
                    @click="goInfoDetail(item.nrid)"
                  >
                    {{ item.title }}
                  </h2>
                  <div class="softMain">
                    <span class="mainTop">
                      <el-avatar :size="30" :src="item.issuephoto"></el-avatar>
                      <span style="margin-left: 10px">{{
                        item.issuename
                      }}</span>
                    </span>
                    <span class="mainFoot" v-if="item.issuedate"
                      >发表日期：{{ item.issuedate }}</span
                    >
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <!-- 搜索框搜索 -->
        <div class="searchInp">
          <el-input placeholder="点击输入楼盘名称" v-model="houseParams.lpname">
            <el-button
              slot="append"
              icon="el-icon-search"
              style="background: #ea4736; color: #fff"
              @click="goSearch()"
            ></el-button>
          </el-input>
        </div>
      </div>
      <!-- 新房推荐 -->
      <div class="news">
        <div id="sectionTitle">
          <h2>新房推荐</h2>
          <span @click="knowMore(1)"
            >了解更多<i class="el-icon-d-arrow-right"></i
          ></span>
        </div>
        <el-empty
          description="暂无数据"
          v-if="houseList.records && houseList.records.length === 0"
        ></el-empty>
        <div class="newHouse" v-else>
          <div
            class="houseMain"
            v-for="item in houseList.records"
            :key="item.lpid"
          >
            <div class="mainItem" @click="goDetail(item)">
              <div class="imgBox">
                <img :src="item.cxfmtpurl" alt="" v-if="item.cxfmtpurl" />
                <img
                  src="https://lyimgs.oss-cn-shenzhen.aliyuncs.com/websiteImg/empty.png"
                  alt=""
                  v-else
                />
              </div>
              <div class="newCenter">
                <div class="centerMain">
                  <div>{{ item.lpname }}</div>
                  <div>{{ item.lpdescribe || '暂无数据' }}</div>
                </div>
                <div class="detailBtn">查看详情</div>
              </div>
              <div class="newFooter">
                <span v-for="lable in item.lplabel" :key="lable">{{
                  lable
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 分页 -->
        <div
          class="pagination"
          v-if="houseList.records && houseList.records.length != 0"
        >
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :page-size="9"
            :hide-on-single-page="true"
            layout="prev, pager, next"
            :total="houseList.total"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 今日资讯 -->
      <div class="today">
        <div id="sectionTitle">
          <h2>今日资讯</h2>
          <span @click="knowMore(2)"
            >了解更多<i class="el-icon-d-arrow-right"></i
          ></span>
        </div>
        <div class="todayCon">
          <div
            class="todayLeft"
            v-for="item in coverImg"
            :key="item.nrid"
            @click="goInfoDetail(item.nrid)"
          >
            <img :src="item.coverphoto" alt="" />
          </div>
          <div class="todayRight">
            <div class="rightLeft">
              <div
                v-for="item in newsList.slice(0, 10)"
                :key="item.nrid"
                @click="goInfoDetail(item.nrid)"
              >
                {{ item.title }}
              </div>
            </div>
            <div class="rightRi">
              <div
                v-for="item in newsList.slice(10, 20)"
                :key="item.nrid"
                @click="goInfoDetail(item.nrid)"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 临深圳新盘推荐（惠州 -->
      <div class="suggest" v-if="suggestHouseList.length> 0">
        <div id="sectionTitle">
          <h2>附近新盘推荐</h2>
          <span @click="knowMore(3)"
            >了解更多<i class="el-icon-d-arrow-right"></i
          ></span>
        </div>
        <div class="suggestCon">
          <suggest-swiper :list="suggestHouseList" />
        </div>
      </div>
      <!-- 多点房导购 -->
      <div class="guide">
        <div id="sectionTitle">
          <h2>多点房·导购</h2>
        </div>
        <swiper ref="mySwiper" :options="swiperOptions" watchSlidesProgress>
          <swiper-slide v-for="item in guideList" :key="item.id">
            <img :src="item.cxfmtpurl" alt="" @click="goLy(item)" />
            <p @click="goLy(item)">{{ item.lpname }}</p>
          </swiper-slide>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
      <!-- 多点房视频 -->
      <div class="videoCon" v-if="videoList.length > 0">
        <div id="sectionTitle">
          <h2>多点房·视频</h2>
          <span @click="knowMore(5)"
            >了解更多<i class="el-icon-d-arrow-right"></i
          ></span>
        </div>
        <swiper ref="mySwiper" :options="swiperOptions" watchSlidesProgress>
          <swiper-slide v-for="item in videoList" :key="item.xcid">
            <div class="playIcon">
              <img
                src="https://lyimgs.oss-cn-shenzhen.aliyuncs.com/websiteImg/videoicons.png"
                alt=""
                @click="playVideo(item)"
              />
            </div>
            <video ref="video" :autoplay="false" id="video" width="100%">
              <source :src="item.videourl" type="video/mp4" />
              <source :src="item.videourl" type="video/ogg" />
              <source :src="item.videourl" type="video/webm" />
            </video>
          </swiper-slide>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
      <!-- 多点房看房团 -->
      <div class="group">
        <div class="groupLeft">
          <img src="@/assets/groupBg.png" alt="" />
          <h2>多点房看房团</h2>
          <div class="groupMain">
            <div class="mainLeft">
              <img
                src="https://lyimgs.oss-cn-shenzhen.aliyuncs.com/websiteImg/group.png"
                alt=""
              />
              <p class="title">看房团火热报名中...</p>
              <p class="tips">共有{{ total }}个可报名看房楼盘</p>
              <p class="sign">共有{{ num }}人已报名看房</p>
            </div>
            <div class="mainRight">
              <p>
                <span>楼盘：</span>
                <el-select
                  v-model="form.lpid"
                  filterable
                  placeholder="请输入"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in allHouse"
                    :key="item.lpid"
                    :label="item.lpname"
                    :value="item.lpid"
                  >
                  </el-option>
                </el-select>
              </p>
              <p>
                <span>姓名：</span>
                <el-input
                  placeholder="请输入您的姓名"
                  v-model="form.name"
                ></el-input>
              </p>
              <p>
                <span>电话：</span>
                <el-input
                  placeholder="请输入您的电话"
                  v-model="form.phone"
                ></el-input>
              </p>
              <el-button
                style="
                  color: #fff;
                  width: 100%;
                  background-color: #88ba29;
                  border-radius: 4px;
                  height: 40px;
                  border: none;
                "
                @click="handelOrder"
                >预约免费看房</el-button
              >
            </div>
          </div>
        </div>
        <div class="groupRight" v-if="advList && advList.lyurl">
          <a :href="advList.lyurl" target="_blank">
            <img
              :src="advList.cxfmtpurl"
              style="height: 325px"
              :alt="advList.lpname"
            />
          </a>
        </div>
      </div>
      <!-- 商铺写字楼 -->
      <div class="office" v-if="officeList.length> 0">
        <div id="sectionTitle">
          <h2>商铺写字楼</h2>
          <span @click="knowMore(4)"
            >了解更多<i class="el-icon-d-arrow-right"></i
          ></span>
        </div>
        <div class="officeCon">
          <office-swiper :list="officeList" />
        </div>
      </div>
      <!-- 在售新房  即将开盘 -->
      <div class="sale">
        <sale-or-opening ref="mychild"/>
      </div>
      <!-- 互动百科 -->
      <div class="interaction">
        <div id="sectionTitle">
          <h2>互动百科</h2>
        </div>
        <div class="interactionCon">
          <div
            class="interactionMain"
            v-for="item in engageList.slice(0, 15)"
            :key="item.eid"
          >
            <li class="interactionItem" @click="goWikiDetail(item)">
              <i class="el-icon-paperclip" style="margin-left: -6px"></i>
              <span>{{ item.equestion }}</span>
            </li>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹窗视频 -->
    <div class="playBox" v-if="showVideo">
      <div class="mask"></div>
      <div class="videoMain">
        <video
          ref="video"
          :autoplay="true"
          controls
          id="video"
          height="100%"
          width="100%"
        >
          <source :src="videoUrl" type="video/mp4" />
          <source :src="videoUrl" type="video/ogg" />
          <source :src="videoUrl" type="video/webm" />
        </video>
        <div class="close" @click="showVideo = false">X</div>
      </div>
    </div>
  </div>
</template>
<script>
// swiper轮播
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { mapState, mapMutations } from 'vuex'
// import SubTopSwiper from '@/components/subTopSwiper.vue' // 顶部轮播图
import SuggestSwiper from './components/suggestSwiper.vue' // 新房推荐轮播图
import OfficeSwiper from './components/officeSwiper.vue' // 新房推荐轮播图
import SaleOrOpening from './components/saleOrOpening.vue' // 在售新房
import { getNews } from '@/api/information.js'
import {
  getBanner,
  getAllVideo,
  getHouse,
  getOrder,
  goOrder,
} from '@/api/home.js'
export default {
  name: 'HomeIndex',
  components: {
    // SubTopSwiper,
    SuggestSwiper,
    SaleOrOpening,
    OfficeSwiper,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      // swiper配置
      swiperOptions: {
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        slidesPerView: 4.3,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      bannerType: 'homes', // banner轮播类型
      // 新房列表参数
      houseParams: {
        housePageNum: 1, // 新房列表 当前页
        housePageSize: 9, // 新房列表 每页数量
        ctqybm: '', // 深圳地区id
        junjmax: '', // 最高价
        junjmin: '', // 最低价
        lpname: '', // 关键字搜索
        order: 3,
      },
      coverImg: [], // 今日资讯图片
      guideList: [], // 导购列表
      videoList: [], // 视频列表
      advList: [], // 看房团旁广告位
      videoUrl: '', // 弹窗播放视频
      showVideo: false,
      total: '', // 可报名楼盘
      num: '', // 已报名人数
      allHouse: [], // 所有楼盘列表
      form: {
        // 看房团报名表单
        lpid: '',
        name: '',
        phone: '',
      },
    }
  },
  watch:{
    getCityId: function() {
      this.init();
      this.$refs.mychild.getAreaData()
      this.$refs.mychild.getAreaData()
    }
  },
  mounted() {
  },
  created() {
    this.init();
  },
  methods: {
    // 导购跳转拉页
    goLy(info) {
      window.open(info.lyurl)
    },
    // 新房推荐 查看详情
    goDetail(info) {
      this.$router.push({
        name: 'HouseDetail',
        query: { lpid: info.lpid },
      })
    },
    // 互动百科详情
    goWikiDetail(info) {
      localStorage.setItem('wikiInfo', JSON.stringify(info))
      this.$router.push('/pages/index/wikiDetail')
    },
    // 播放视频
    playVideo(info) {
      this.videoUrl = info.videourl
      this.showVideo = true
    },
    // 获取今日资讯图片
    async getImgData() {
      await getNews({
        pageSize: 1,
        pageNum: 1,
        ctid: this.$store.state.ctid,
        type: 2,
        iscover: 1,
      })
        .then((res) => {
          this.coverImg = res.data.records
        })
        .catch((err) => console.log(err))
        .finally(() => {})
    },
    // 获取导购列表
    async getGuide() {
      await getBanner({
        pageSize: 6,
        pageNum: 1,
        newbqId: 3,
        xctype: 1,
        ctid: this.$store.state.ctid,
      })
        .then((res) => {
          this.guideList = res.data.records
        })
        .catch((err) => console.log(err))
        .finally(() => {})
    },
    // 获取视频列表
    async getVideo() {
      await getAllVideo({
        pageNum: 1,
        pageSize: 200,
      })
        .then((res) => {
          this.videoList = res.data.records
        })
        .catch((err) => console.log(err))
        .finally(() => {})
    },
    // 获取看房团旁广告位
    async getAdvertise() {
      await getBanner({
        pageSize: 1,
        pageNum: 1,
        newbqId: 8,
        xctype: 10,
        ctid: this.$store.state.ctid,
      })
        .then((res) => {
          this.advList = res.data.records[0]
        })
        .catch((err) => console.log(err))
        .finally(() => {})
    },
    // 获取可报名楼盘
    async getTotal() {
      await getHouse({
        pageSize: 10000,
        pageNum: 1,
      })
        .then((res) => {
          this.total = res.data.total
          this.allHouse = res.data.records
        })
        .catch((err) => console.log(err))
        .finally(() => {})
    },
    // 获取已报名人数
    async getNum() {
      await getOrder({
        pageSize: 10000,
        pageNum: 1,
      })
        .then((res) => {
          this.num = res.data.total
        })
        .catch((err) => console.log(err))
        .finally(() => {})
    },
    // 立即预约
    async handelOrder() {
      if (!this.form.name || !this.form.phone || !this.form.lpid) {
        this.$message('请先填写您的信息')
        return
      }
      if (this.form.phone && !/^1[3-9]\d{9}$/.test(this.form.phone)) {
        this.$message('请输入正确手机号')
        return
      }
      await goOrder({
        name: this.form.name,
        phone: this.form.phone,
        lpid: this.form.lpid,
      })
        .then((res) => {
          if (res.code === '200') {
            this.form = {}
            window._agl && window._agl.push(['track', ['success', { t: 3 }]])
            this.$message({
              message: '预约成功！',
              type: 'success',
            })
          }
        })
        .catch((err) => {
          if (err.code === '402') {
            this.$message(err.msg)
            return
          } else if (err.code === '403') {
            this.$message(err.msg)
            return
          }
        })
        .finally(() => {})
    },
    // 新房列表接口
    getHouseRecord() {
      // 新房列表
      this.getHouseData({
        // newbqId: 9,
        ctid: this.$store.state.ctid,
        pageSize: this.houseParams.housePageSize,
        pageNum: this.houseParams.housePageNum,
        ctqybm: this.houseParams.ctqybm, // 深圳地区id
        junjmax: this.houseParams.junjmax, // 最高价
        junjmin: this.houseParams.junjmin, // 最低价
        lpname: this.houseParams.lpname, // 关键字搜索
        order: this.houseParams.order,
      })
    },
    // 新房分页获取当前页
    handleCurrentChange(val) {
      this.houseParams.housePageNum = val
      // 新房列表
      this.getHouseRecord()
      this.$forceUpdate()
    },
    // 选中深圳地区筛选
    handelArea(info) {
      this.$store.state.areaList.map((item) => {
        if (info.ctqybm === item.ctqybm) {
          this.houseParams.ctqybm = info.ctqybm
          item.isSelect = true
        } else {
          item.isSelect = false
        }
      })
      this.getHouseRecord()
      this.$forceUpdate()
    },
    // 选中单价筛选
    handelPrice(info) {
      console.log(info)
      this.$store.state.priceList.map((item) => {
        if (info.djid === item.djid) {
          this.houseParams.junjmax = info.djmaxvalue
          this.houseParams.junjmin = info.djminvalue
          item.isSelect = true
        } else {
          item.isSelect = false
        }
      })
      this.getHouseRecord()
      this.$forceUpdate()
    },
    // 搜索框搜索
    goSearch() {
      this.getHouseRecord()
    },
    // 去资讯详情
    goInfoDetail(nrid) {
      this.$router.push({
        name: 'InfoDetail',
        query: { nrid: nrid },
      })
    },
    // 了解更多
    knowMore(num) {
      if (num === 1) {
        this.$router.push('/newHouse')
      } else if (num === 2) {
        this.$router.push('/pages/news/index')
      } else if (num === 3) {
        this.$router.push('/newHouse')
      } else if (num === 4) {
        this.$router.push('/office')
      } else if (num === 5) {
        this.$router.push('/allVideo')
      }
    },
    // 轮播图跳转拉页
    toNewPage() {
      window.open('https://www.duodianfang.com/zhuanti/qhzx/index.html')
    },

    ...mapMutations([
      'getAreaData',
      'getPriceData',
      'getHouseData',
      'getNewsData',
      'getSzHouseData',
      'getEngageData',
      'getMediumData',
      'getOfficeData',
    ]),

    // 初始化
    init () {
      this.getImgData()
      // 新房列表
      this.getHouseRecord()
      // 深圳地区
      this.getAreaData({
        ctid: this.$store.state.ctid,
        pageNum: 1,
        pageSize: 100,
      }),
        // 价格区间
        this.getPriceData({
          ctid: this.$store.state.ctid,
          pageNum: 1,
          pageSize: 100,
        }),
        // 资讯列表
        this.getNewsData({
          pageSize: 20,
          pageNum: 1,
          ctid: this.$store.state.ctid,
          type: 2,
        }),
        // 媒体软文列表
        this.getMediumData({
          pageSize: 5,
          pageNum: 1,
          ctid: this.$store.state.ctid,
          type: 5,
        })
      // 临深楼盘列表
      this.getSzHouseData({
        pageSize: 5,
        pageNum: 1,
        ctid: this.$store.state.ctid,
        scrId: 20,
      }),
        // 写字楼列表
        this.getOfficeData({
          pageSize: 5,
          pageNum: 1,
          ctid: this.$store.state.ctid,
        })
      // 互动百科列表
      this.getEngageData({
        pageSize: 15,
        pageNum: 1,
      }),
        this.getGuide()
      this.getVideo()
      this.getAdvertise()
      this.getTotal()
      this.getNum()
    }
  },
  computed: {
    ...mapState([
      'areaList',
      'priceList',
      'houseList',
      'newsList',
      'suggestHouseList',
      'engageList',
      'meaiumList',
      'pageTotal',
      'officeList',
    ]),
    getCityId () {
      return this.$store.state.ctid;
    }
  },
}
</script>
<style scoped lang="scss">
#sectionTitle {
  margin: 25px 0 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    color: #516dd1;
    cursor: pointer;
  }
}
.Con {
  position: relative;

  // 搜索
  .conSearch {
    margin: 30px 0;
    .searchTop {
      display: flex;
      .region {
        text-align: left;
        padding: 20px 0 0px 20px;
        box-shadow: 0px 0px 10px #e0dede;
        border-radius: 20px;
        flex: 4;
        .area {
          // margin-bottom: 20px;
          line-height: 3;
          display: flex;
          .areaTitle {
            color: #333;
            font-size: 24px;
            margin-right: 20px;
            min-width: 50px;
          }
          .areaItem {
            margin-bottom: 20px;
            line-height: 2;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .isSelect {
              margin: 0 15px;
              color: #ff1b01;
              font-size: 18px;
              cursor: pointer;
            }
            .unSelect {
              margin: 0 15px;
              color: #7c7c7c;
              cursor: pointer;
            }
          }
        }
        .price {
          line-height: 3;
          display: flex;
          .priceTitle {
            color: #333;
            font-size: 24px;
            margin-right: 20px;
            min-width: 50px;
          }
          .priceItem {
            line-height: 2;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .isSelect {
              margin: 0 10px;
              color: #ff1b01;
              font-size: 18px;
            }
            .unSelect {
              margin: 0 10px;
              color: #7c7c7c;
              cursor: pointer;
            }
          }
        }
      }
      // 媒体软文
      .softCon {
        flex: 2;
        padding: 20px 20px 0 20px;
        box-shadow: 0px 0px 10px #e0dede;
        border-radius: 16px;
        text-align: left;
        width: 400px;
        height: 200px;
        margin-left: 30px;
        // position: absolute;
        z-index: 9;
        background: #fff;
        // left: 360px;
        // top: 290px;
        .softTitle {
          background-color: #4e6a80;
          font-size: 20px;
          color: #fff;
          padding: 3px 12px;
          border-radius: 10px;
          margin-bottom: 15px;
          display: inline-block;
        }
        .softMain {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;
          .mainTop {
            display: flex;
            justify-content: left;
            align-items: center;
            font-size: 14px;
          }
          .mainFoot {
            font-size: 14px;
          }
        }
        :deep() {
          .el-carousel__indicator--horizontal {
            display: inline-block;
            padding: 0;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #ccc;
            margin: 5px;
          }
          .el-carousel__indicator.el-carousel__indicator--horizontal.is-active {
            display: inline-block;
            padding: 0;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #999;
            margin: 10px 5px;
          }
          .el-carousel__button {
            width: 0;
          }
          .el-carousel__arrow {
            top: 35%;
          }
        }
      }
    }
    .searchInp {
      margin: 30px 30px 0 30px;
      :deep() {
        .el-input__inner:focus {
          border: 1px solid #ff1b01 !important;
        }
      }
    }
  }
  // 新房推荐
  .news {
    text-align: center;
    .newHouse {
      text-align: left;
      .houseMain {
        display: inline-block;
        margin: 0 30px 30px 0;
        width: calc((100% - 60px) / 3);
        &:nth-child(3n) {
          margin-right: 0;
        }
        .mainItem {
          background-color: #f7f7f7;
          padding: 20px;
          border-radius: 20px;
          display: flex;
          flex-direction: column;
          .imgBox {
            height: 240px;
            overflow: hidden;
            border-radius: 20px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .newCenter {
            display: flex;
            justify-content: space-between;
            margin: 20px 0;
            border-bottom: 1px solid #eaeaea;
            padding-bottom: 20px;
            .centerMain {
              text-align: left;
              div:nth-child(1) {
                color: #333;
                font-size: 18px;
                font-weight: 600;
              }
              div:nth-child(2) {
                color: #767676;
                font-size: 16px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 240px;
              }
            }
            .detailBtn {
              color: #5c6794;
              background-color: #fff;
              border-radius: 10px;
              padding: 5px 15px;
              display: flex;
              align-items: center;
              font-size: 18px;
              font-weight: 600;
              cursor: pointer;
            }
          }
          .newFooter {
            display: flex;
            justify-content: left;
            span:nth-child(1) {
              background-color: #e66057;
              color: #333;
              border-radius: 5px;
              padding: 2px 10px;
              font-size: 14px;
              margin-right: 15px;
            }
            span:nth-child(2) {
              background-color: #ebe985;
              color: #333;
              border-radius: 5px;
              padding: 2px 10px;
              font-size: 14px;
            }
          }
        }
      }
    }
    .pagination {
      // margin-top: 20px;
      :deep() {
        .number.active {
          background-color: #f24736 !important;
        }
        .number:hover {
          color: #606266;
        }
      }
    }
  }
  // 今日资讯
  .today {
    // padding: 0 300px;
    text-align: center;
    .todayCon {
      display: flex;
      height: 309px;
      overflow: hidden;
      .todayLeft {
        width: 40%;
        height: 100%;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .todayRight {
        width: 60%;
        display: flex;
        // justify-content: left;
        .rightLeft {
          line-height: 2.5;
          margin: 0 0 0 40px;
          text-align: left;
          width: 50%;
          div {
            cursor: pointer;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            width: 90%;
          }
          div:hover {
            cursor: pointer;
            color: #d73435;
            text-decoration: underline;
            font-weight: 600;
          }
        }
        .rightRi {
          line-height: 2.5;
          text-align: left;
          width: 50%;
          div {
            cursor: pointer;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            width: 80%;
          }
          div:hover {
            cursor: pointer;
            color: #d73435;
            text-decoration: underline;
          }
        }
      }
    }
  }
  // 临深圳新盘推荐
  .suggest {
    // padding: 0 300px;
    text-align: center;
    .suggestCon {
      width: 100%;
      // height: 300px;
    }
  }
  // 导购
  .guide {
    .swiper-container {
      width: 100%;
      margin: 20px 0;
      .swiper-slide {
        text-align: center;
        margin-right: 10px;
        img {
          width: 100%;
          height: 220px;
          border-radius: 6px;
          margin-top: 30px;
          cursor: pointer;
          &:first-child {
            margin-top: 0;
          }
        }
        p {
          margin-top: 10px;
          cursor: pointer;
        }
        &:nth-child(6n) {
          margin-right: 0;
        }
      }
      // 切换按钮样式
      // hover时才显示切换按钮
      &:hover {
        .swiper-button-prev,
        .swiper-button-next {
          display: flex;
        }
      }
      .swiper-button-prev,
      .swiper-button-next {
        display: none;
        width: 50px;
        height: 50px;
        color: white;
        border-radius: 50%;
        background-color: #666;
        opacity: 0.6;
        &:after {
          font-size: 18px;
        }
        // 第一个或最后一个隐藏切换按钮
        &.swiper-button-disabled {
          display: none;
        }
      }
    }
  }
  // 视频
  .videoCon {
    .swiper-container {
      width: 100%;
      height: 160px;
      margin: 20px 0;
      .swiper-slide {
        text-align: center;
        margin-right: 10px;
        background-color: #000;
        .playIcon {
          position: absolute;
          left: 0;
          top: 30%;
          right: 0;
          bottom: 0;
          z-index: 9;
          img {
            cursor: pointer;
          }
        }
      }
      // 切换按钮样式
      // hover时才显示切换按钮
      &:hover {
        .swiper-button-prev,
        .swiper-button-next {
          display: flex;
        }
      }
      .swiper-button-prev,
      .swiper-button-next {
        display: none;
        width: 50px;
        height: 50px;
        color: white;
        border-radius: 50%;
        background-color: #666;
        opacity: 0.6;
        &:after {
          font-size: 18px;
        }
        // 第一个或最后一个隐藏切换按钮
        &.swiper-button-disabled {
          display: none;
        }
      }
    }
  }
  // 看房团
  .group {
    display: flex;
    margin: 10px 0 20px 0;
    align-items: center;
    .groupLeft {
      flex: 3;
      margin-right: 20px;
      position: relative;
      h2 {
        position: absolute;
        top: 8px;
        left: 8px;
        color: #fff;
      }
      img {
        width: 100%;
      }
      .groupMain {
        position: absolute;
        top: 60px;
        right: 40px;
        bottom: 20px;
        left: 40px;
        display: flex;
        .mainLeft {
          position: relative;
          flex: 1;
          img {
            height: 100%;
          }
          .title {
            position: absolute;
            top: 70px;
            left: 20px;
            color: #88ba29;
            font-size: 26px;
          }
          .tips {
            position: absolute;
            top: 120px;
            left: 20px;
            color: #666;
            font-size: 16px;
          }
          .sign {
            position: absolute;
            top: 150px;
            left: 20px;
            color: #666;
            font-size: 16px;
          }
        }
        .mainRight {
          flex: 1;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          margin-left: 20px;
          p {
            display: flex;
            align-items: center;
            span {
              min-width: 80px;
            }
            :deep() {
              .el-input__inner:focus {
                border: 1px solid #88ba29 !important;
              }
            }
          }
        }
      }
    }
    .groupRight {
      flex: 1;
    }
  }
  // 写字楼推荐
  .office {
    // padding: 0 300px;
    text-align: center;
  }
  // 在售新房  即将开盘
  .sale {
    // padding: 0 300px;
    text-align: center;
  }
  // 互动百科
  .interaction {
    // padding: 0 300px;
    text-align: center;
    .interactionCon {
      text-align: left;
      margin-right: -100px;
      .interactionMain {
        display: inline-block;
        margin: 0 10px 30px 0;
        width: calc((100% - 40px) / 3);

        &:nth-child(3n) {
          margin-right: 0;
        }
        .interactionItem {
          cursor: pointer;
          color: #595959;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
          width: 90%;
        }
        .interactionItem:hover {
          cursor: pointer;
          color: #1c2533;
          text-decoration: underline;
        }
      }
    }
  }
  .playBox {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    .mask {
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0.6;
    }
    .videoMain {
      max-width: 1152px;
      width: 40%;
      // height: 60%;
      position: absolute;
      left: 30%;
      top: 20%;
      background: #000;
      .close {
        position: absolute;
        right: 30px;
        top: 30px;
        color: #fff;
        font-size: 28px;
        cursor: pointer;
      }
    }
  }
}
</style>
